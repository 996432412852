<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && !popupParam.disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="hazardData"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
              <c-btn v-if="isOld && !popupParam.disabled && editable" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-text 
                required
                :editable="editable"
                :disabled="popupParam.disabled"
                type="edit"
                label="공정명"
                name="processCd"
                v-model="hazardData.processCd" />
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <c-dept 
                :editable="editable"
                :disabled="popupParam.disabled"
                type="edit"
                label="LBLDEPT"
                name="deptCd"
                v-model="hazardData.deptCd" />
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                label="단위작업장소"
                :disabled="popupParam.disabled"
                name="workPlace"
                v-model="hazardData.workPlace">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                suffix="명"
                :editable="editable"
                :disabled="popupParam.disabled"
                label="근로자수"
                name="workCount"
                v-model="hazardData.workCount">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="작업내용"
                name="workContents"
                v-model="hazardData.workContents">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="근로형태"
                name="shiftType"
                placeholder="o조o교대o시간"
                v-model="hazardData.shiftType">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="발생형태"
                name="occurType"
                v-model="hazardData.occurType">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="발생시간"
                name="occurTime"
                v-model="hazardData.occurTime">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="측정위치"
                name="measPositionName"
                v-model="hazardData.measPositionName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="근로자명"
                name="workerName"
                v-model="hazardData.workerName">
              </c-text>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="popupParam.disabled"
                label="근로자"
                :data="hazardData"
                userName="workerName"
                name="workerUserId"
                v-model="hazardData.workerUserId">
              </c-field>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-datepicker
                :editable="editable"
                :disabled="popupParam.disabled"
                label="시간~종료시간"
                type="time"
                :minuteStep="1"
                :range="true"
                name="measTime"
                v-model="measTime">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                suffix="회"
                :editable="editable"
                :disabled="popupParam.disabled"
                type="number"
                label="측정횟수"
                name="measCount"
                v-model="hazardData.measCount">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="측정치기타"
                name="measValueEtc"
                v-model="hazardData.measValueEtc">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                required
                :editable="editable"
                :disabled="popupParam.disabled"
                type="number"
                :numberOptions="{
                  currency: null,
                  precision: 5,
                }"
                label="측정치"
                name="measValue"
                v-model="hazardData.measValue">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="전회기타"
                name="twaPrevEtc"
                v-model="hazardData.twaPrevEtc">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                type="number"
                label="전회"
                :numberOptions="{
                  currency: null,
                  precision: 5,
                }"
                name="twaPrev"
                v-model="hazardData.twaPrev">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="금회기타"
                name="twaCurrEtc"
                v-model="hazardData.twaCurrEtc">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                type="number"
                label="금회"
                :numberOptions="{
                  currency: null,
                  precision: 5,
                }"
                name="twaCurr"
                v-model="hazardData.twaCurr">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                type="number"
                :numberOptions="{
                  currency: null,
                  precision: 5,
                }"
                label="노출기준"
                name="exposureStandard"
                v-model="hazardData.exposureStandard">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="결과"
                name="exposureResult"
                v-model="hazardData.exposureResult">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="측정방법"
                name="measMethod"
                v-model="hazardData.measMethod">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="발생주기명"
                name="occurrenceCycleName"
                v-model="hazardData.occurrenceCycleName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="폭로시간"
                type="number"
                name="exposureTime"
                v-model="hazardData.exposureTime">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :editable="editable"
                :disabled="popupParam.disabled"
                label="측정구분"
                name="measTypeFst"
                v-model="hazardData.measTypeFst">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="비고"
                name="remark"
                v-model="hazardData.remark"
              ></c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="측정자"
                name="measUser"
                v-model="hazardData.measUser"
              ></c-text>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="통합구분"
                name="integratedType"
                v-model="hazardData.integratedType"
              ></c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="그룹구분"
                name="groupType"
                v-model="hazardData.groupType"
              ></c-text>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
              <c-text
                :disabled="popupParam.disabled"
                label="직종명"
                name="occupationName"
                v-model="hazardData.occupationName"
              ></c-text>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="Trace"
                name="fstTrace"
                v-model="hazardData.fstTrace"
              ></c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label="Trace"
                name="secTrace"
                v-model="hazardData.secTrace"
              ></c-text>
            </div> -->
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
              <c-text
                :disabled="popupParam.disabled"
                label=""
                name="secTrace"
                v-model="hazardData.secTrace"
              ></c-text>
            </div> -->
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-measure-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
        workMeasurementResultId: '',
        disabled: false,
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      hazardData: {
        workMeasurementResultId: '',
        workMeasurementPlanId: '',
        deptCd: '',
        processCd: '',
        workPlace: '',
        hazardCd: '',
        workCount: '',
        workContents: '',
        shiftType: '',
        occurType: '',
        occurTime: '',
        measPositionName: '',
        workerName: '',
        workerUserId: '',
        measStartTime: '',
        measEndTime: '',
        captureTime: '',
        measCount: '',
        measValueEtc: '',
        measValue: '',
        twaPrevEtc: '',
        twaPrev: '',
        twaCurrEtc: '',
        twaCurr: '',
        exposureStandard: '',
        afterExposureStandard: '',
        exposureDivision: '',
        exposureUnit: '',
        exposureResult: '',
        measMethod: '',
        collectionMethod: '',
        analyzeCode: '',
        analyzeMethod: '',
        occurrenceCycleName: '',
        exposureTime: '',
        measTypeFst: '',
        remark: '',
        beforeExposureStandard: '',
        measUser: '',
        integratedType: '',
        groupType: '',
        chemGroupCd: '',
        chemGroupOrder: '',
        measDate: '',
        noiseGroupCd: '',
        noiseGroupOrder: '',
        analysisRequest: '',
        groupCd: '',
        measTypeSec: '',
        excludingNoise: '',
        exposureStdExceeded: '',
        occupationCode: '',
        occupationName: '',
        fstTrace: '',
        secTrace: '',
        regUserId: '',
        regDt: '',
        chgUserId: '',
        chgDt: '',
        noiseFlag: 'Y',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      measTime: [],
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.workMeasurementResultId)
    }
  }, 
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.workmeas.result.get.url;
      this.insertUrl = transactionConfig.sai.workmeas.result.insert.url;
      this.updateUrl = transactionConfig.sai.workmeas.result.update.url;
      this.saveUrl = transactionConfig.sai.workmeas.result.insert.url;
      this.deleteUrl = transactionConfig.sai.workmeas.result.delete.url;

      if (this.popupParam.workMeasurementPlanId) {
        this.hazardData.workMeasurementPlanId = this.popupParam.workMeasurementPlanId;
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.workMeasurementResultId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.workMeasurementResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.hazardData = _result.data;
          if (_result.data.measStartTime && _result.data.measEndTime) {
            this.measTime = [_result.data.measStartTime, _result.data.measEndTime];
          }
        },);
      }
    },
    saveData() {
      if (this.popupParam.workMeasurementResultId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.hazardData.regUserId = this.$store.getters.user.userId;
              this.hazardData.chgUserId = this.$store.getters.user.userId;
              if (this.measTime && this.measTime.length > 0) {
                this.hazardData.measStartTime = this.measTime[0]
                this.hazardData.measEndTime = this.measTime[1]
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.workMeasurementResultId = result.data
      this.getDetail();
    },
    
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.workMeasurementResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
